import Flickity from 'flickity';
const FlickitySlider = () => {

    Flickity.prototype.ontouchstart = (function(original) {
        return function(event) {
            this._pointerUp(event, event.changedTouches[0]);
            original.apply(this, arguments);
        };
    }(Flickity.prototype.ontouchstart));

    Flickity.prototype._createResizeClass = function () {
        this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    var resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function () {
        this.element.classList.remove('flickity-resize');
        resize.call(this);
        this.element.classList.add('flickity-resize');
    };

    const slider = document.querySelectorAll(
        'body.home .c-recent-projects__col--projects');

    function flickitySlider () {


        const $carousel = new Flickity(slider[0], {
            cellSelector: '.c-recent-project__link',
            cellAlign: 'left',
            contain: true,
            draggable: '>1',
            wrapAround: false,
            prevNextButtons: false,
            pageDots: false,
            watchCSS: true,
            adaptiveHeight: true
        });

        slider[0].addEventListener( 'dragStart.flickity', function( event, pointer ) {
            document.ontouchmove = function (e) {
                e.preventDefault();
            }
        });
        slider[0].addEventListener( 'dragEnd.flickity', function( event, pointer ) {
            document.ontouchmove = function (e) {
                return true;
            }
        });
    }

    window.addEventListener("resize", function() {
        if (slider.length) {
            flickitySlider();
        }
    });

    window.addEventListener("load", function() {
        if (slider.length) {
            flickitySlider();
        }
    });


}

export default FlickitySlider
