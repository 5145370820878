import HandyCollapse from 'handy-collapse'

const ExpandableText = () => {
    // Read more buttons
    new HandyCollapse({
        nameSpace: 'readmore',
        activeClass: 'active',
        isAnimation: true,
        closeOthers: false,
        animationSpeed: 300,
        cssEasing: 'ease',
    })
}

export default ExpandableText
